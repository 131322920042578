import React from 'react';
import logoTextSvg from './Visomnia-Text-min.svg';
import logoTextPng from './Visomnia-Text.png';
import './App.css';

import Loadable from 'react-loadable';
import { Link, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const LoadableImprint = Loadable({
  loader: () => import('./components/Imprint'),
  loading() {
    return <div>Loading Imprint...</div>
  }
} as any);

function App() {
  return (
    <div className="App">
      <Helmet><title>VISOMNIA</title></Helmet>
      <header className="App-header">
        <picture>
          <source srcSet={logoTextSvg} type="image/svg+xml" />
          <source srcSet={logoTextPng} type="image/png" />
          <img src={logoTextSvg} className="App-logo" alt="VISOMNIA" />
        </picture>
        <p></p>
      </header>
      <main className="App-main">
        <Routes>
          <Route path="/imprint" element={<>
            <aside className="App-imprint"><LoadableImprint /></aside>
            <Link className="App-imprint-close" to={"/"} aria-role="button" aria-label="close" title='close imprint'>close</Link>
          </>} />
        </Routes>
      </main>
      <footer className="App-footer">
        <Link to="/imprint">Imprint</Link>
      </footer>
    </div>
  );
}

export default App;
